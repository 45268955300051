module.exports = {
    getUserData: function() {
        if (window.$cookies.isKey("user")) {
            return window.$cookies.get("user");
        }
    },
    getAuthHeaders: function() {
        const user = this.getUserData();
        if (user != (null || undefined)) {
            this.$apiHeaders["headers"]["Authorization"] = "Bearer " + user.access_token;
        }
        return this.$apiHeaders;
    },
    startProgressBar: function() {
        return this.$Progress.start();
    },
    updateProgressBar: function(status) {
        if (status) {
            return this.$Progress.finish();
        } else {
            return this.$Progress.fail();
        }
    },
    deleteEmptyKeys: function(data) {
        var query = {};
        for (var key in data) {
            (data[key] !== '') ? query[key] = data[key]: '';
        }
        return query;
    },
    getApiUrlByProcess: function(process, api_end_point) {
        return this.$api[process][api_end_point];
    },
    getUserSetting: function(setting_name) {
        let current_user = this.getUserData();
        let current_user_settings = current_user.user_details.user_settings;
        if (Object.prototype.hasOwnProperty.call(current_user_settings, setting_name)) {
            return current_user_settings[setting_name];
        } else {
            return null;
        }
    },
    nameWithId ({ full_name, id }) {
        if (id) {
            return `${full_name} [IES-${id}]`
        } else {
            return 'Type Name or ID'
        }
    },
    hideModal(id) {
        this.$bvModal.hide(id);
    },
    showModal(id) {
        this.$bvModal.show(id);
    }
}