module.exports = {
    headers: {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, PATCH, PUT, DELETE, OPTIONS",
            "Accept": "application/json"
        }
    },
    server_error: "Something went wrong. Please contact administrator.",
    application_title: 'Infoesearch Portal 1.6'
}
