import api from "../../../public/config.js";
// split host url and port from domain if localhost exists
const host = location.host.split(':');
let apiBaseUrl = (api.apiBaseUrl.includes("localhost")) ? api.apiBaseUrl.replace("localhost", host[0]) : api.apiBaseUrl;

// use original https protocol if in production
const protocol = location.protocol;
apiBaseUrl = apiBaseUrl.replace("http:", protocol);

const baseUrl = apiBaseUrl + 'api/';
const apiEndpoints = {
    host_url: apiBaseUrl,
    login: baseUrl + "login",
    get_app_settings: baseUrl + "app-settings",
    get_user_setting_with_fields: baseUrl + "user-settings/with-fields",
    update_user_settings: baseUrl + "user-settings/update",
    change_password: baseUrl + "users/change-password",
    update_profile: baseUrl + "users/update-profile",
    get_employees: baseUrl + "employees",
    get_employees_list: baseUrl + "employees/list",
    get_my_details: baseUrl + "employees/get-my-details",
    get_my_team: baseUrl + "employees/get-my-team",
    get_my_history: baseUrl + "employees/get-my-history",
    update_my_details: baseUrl + "employees/update-my-details",
    get_my_attendance: baseUrl + 'employee-attendance/get',
    get_team_attendance: baseUrl + 'employee-attendance/team',
    get_leaves: baseUrl + "leaves",
    create_leave: baseUrl + "leaves/create",
    cancel_leave: baseUrl + "leaves/cancel",
    leaves_approval: baseUrl + "leaves/get-approval-leaves",
    view_leave_approval: baseUrl + "leaves/get-approval-leave",
    approved_leave: baseUrl + "leaves/approve",
    multi_leaves_approve: baseUrl + "leaves/multi-leaves-approve",
    reject_leave: baseUrl + "leaves/reject",
    get_remaining_leaves:baseUrl + "leaves/remaining-leaves",
    employee_leave_apply:baseUrl + "leaves/employee-leave-apply",
    get_my_holidays: baseUrl + "holidays/my-holidays",
    get_working_shift_list: baseUrl + "working-shifts/list",
    get_employee_shifts: baseUrl + "employee-shift",
    assign_employee_shift: baseUrl + "employee-shift/assign",
    update_employee_shift: baseUrl + "employee-shift/update",
    delete_employee_shift: baseUrl + "employee-shift/delete",
    get_payroll: baseUrl + "employee-payroll",
    get_my_leave_balance_history: baseUrl + "leave-balance/get-my-leave-balance-history", 
    get_employee_leave_balance_history: baseUrl + "leave-balance/get-employee-leave-balance-history",
    get_compoffs: baseUrl + "compoffs",
    get_compoff_approvals: baseUrl + "compoffs/get-compoff-approvals",
    apply_compoffs: baseUrl + "compoffs/create",
    reject_compoff: baseUrl + "compoffs/reject",
    approve_compoff: baseUrl + "compoffs/approve",
    multi_approve_compoff: baseUrl + "compoffs/multi-approve",
    cancel_compoffs: baseUrl + "compoffs/cancel",
    my_shifts: baseUrl + "employee-shift/my-shifts",
    get_leaves_encashes: baseUrl + "leaves-encash",
    leaves_encashed_apply: baseUrl + "leaves-encash/create",
    get_search_employee: baseUrl + "employees/search-employees",
}

export default apiEndpoints;
