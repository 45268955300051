<template>
    <div>
        <header class="noprint">
            <!-- NAVBAR -->
            <nav class="navbar navbar-expand-md navbar-dark bg-dark">
                <a class="navbar-brand pb-2" href="/">
                    {{$applicationTitle}}
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ml-auto" >
                        <li class="nav-item dropdown" v-for="(item,  index) in menus" :key="index">
                            <a class="nav-link dropdown-toggle text-nowrap" v-if="item.submenu && hasPermission(item.permission)" href="#" :id="str_snake_case(item.name)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{item.name}}
                            </a>
                            <a class="nav-link text-nowrap" v-else-if="hasPermission(item.permission)"  :href="item.path">{{item.name}}</a>
                            <ul class="dropdown-menu dropdown-menu-right dropleft" :aria-labelledby="str_snake_case(item.name)">
                                <li class="dropdown-submenu" v-for="submenu_item in item.submenu" :key="submenu_item.name" :href="submenu_item.path">
                                    <a class="dropdown-item dropdown-toggle text-nowrap" href="#" v-if="submenu_item.submenu && hasPermission(submenu_item.permission)">{{submenu_item.name}}</a>
                                    <a class="dropdown-item text-nowrap" v-else-if="hasPermission(submenu_item.permission)"  :href="submenu_item.path">{{submenu_item.name}}</a>
                                    <ul class="dropdown-menu">
                                        <li v-for="sub_menu_item in submenu_item.submenu" :key="sub_menu_item.name">
                                            <a class="dropdown-item text-nowrap" v-if="hasPermission(sub_menu_item.permission)" :href="sub_menu_item.path">{{sub_menu_item.name}}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <!-- ADMIN -->
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <font-awesome-icon icon="user"></font-awesome-icon>&nbsp;{{ userFirstName(userData.user_details.first_name) }}
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right dropleft" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <span class="dropdown-item disabled">
                                        {{ userFirstName(userData.user_details.first_name) }}
                                    </span>
                                </li>
                                <li>
                                    <span class="dropdown-item disabled">{{ userData.user_details.email }}</span>
                                </li>
                                <hr>
                                <li>
                                    <router-link :to="{name:'profile'}">
                                        <span class="dropdown-item">Profile</span>
                                    </router-link>
                                </li>
                               <!-- <li>
                                    <router-link :to="{name:'home'}">
                                        <span
                                            class="dropdown-item"
                                            v-if="checkPermit('CAN_DO_ANYTHING')"
                                        >Admin</span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link :to="{name:'#'}">
                                        <span class="dropdown-item">Profile</span>
                                    </router-link>
                                </li> -->
                                <li>
                                    <router-link 
                                        :to="{name:'settings'}"
                                        v-if="hasPermission('CAN_MANAGE_USER_SETTINGS')"
                                    >
                                        <span class="dropdown-item">User Settings</span>
                                    </router-link>
                                </li>
                                <li>
                                    <span style="cursor:pointer" class="dropdown-item" v-on:click="logout()">Logout</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
            <!-- NAVBAR -->
        </header>
        <div class="clear-fix"></div>
        <div class="container-fluid mt-2">
            <div class="row">
                <main role="main" class="col-md-12 ml-sm-auto col-lg-12 px-4">
                    <router-view />
                </main>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userData: {},
        };
    },
    created() {
        this.userData = window.$cookies.get("user");
    },
    mounted(){
        // NAVBAR
        window.$(document).click(function (){
            window.$('.navbar-nav a.dropdown-toggle').parents('.navbar-nav').first().find('.show').removeClass("show");
        });
        window.$('.navbar-nav a.dropdown-toggle').on('click', function() {
            if (!window.$(this).next().hasClass('show')) {
                window.$(this).parents('.navbar-nav').first().find('.show').removeClass("show");
            }
            var $subMenu = window.$(this).next(".dropdown-menu");
            $subMenu.toggleClass('show');
            window.$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function() {
                window.$('.dropdown-submenu .show').removeClass("show");
            });
            return false;
        });
        
    },
    computed:{
        menus(){
            return this.$store.getters.getAppSetting("user_interface", "menus");
        }
    },
    methods: {
        logout: function() {
            if (window.$cookies.isKey("user")) {
                window.$cookies.remove("user");
            }
            this.$nextTick(() =>{
                this.$router.push({ name: "login" });
            })
        },
        canViewMenu(menu_name) {
            let page_access = false;
            this[menu_name].forEach(page => {
                if (this.hasPermission(page.permission)) {
                    page_access = true;
                    return;
                }
            });
            return page_access;
        },
        str_snake_case(string) {
            return string.replace(/\d+/g, ' ')
                .split(/ |\B(?=[A-Z])/)
                .map((word) => word.toLowerCase())
                .join('_');
        },
        userFirstName(value){
            var nameArr = value.split(' ');
            return this.str_title(nameArr[0])
        }
    }
};
</script>

<style lang="scss">
#nprogress .bar {
    height: 5px !important;
}
#app {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #2e2e2e;
    text-align: left;
}
a:hover {
   text-decoration: none !important; 
}
.navbar {
    padding: 0rem 1rem !important;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-menu > li> .dropdown-item:hover {
    background-color:#007bff;
    color:#fff !important;
}
.dropdown-menu > li>a .dropdown-item:hover {
    background-color:#007bff;
    color:#fff !important;
}
@media print {
    .noprint {
        display:none;
    }
}
</style>