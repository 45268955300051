import Vue from "vue";
import Router from "vue-router";
import DefaultLayout from "./views/layouts/DefaultLayout.vue";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [{
            path: "/",
            redirect: "/login"
        },
        {
            path: "/home",
            component: DefaultLayout,
            children: [{
                    path: "/home",
                    name: "home",
                    component: () =>
                        import ("./views/admin/Dashboard.vue")
                },
                {
                    path: "/employee/my-details",
                    name: "my-details",
                    component: () =>
                        import ("./views/pages/employees/EmployeeDetails.vue")
                },
                {
                    path: "/employee/edit-my-details",
                    name: "edit-my-details",
                    component: () =>
                        import ("./views/pages/employees/EditEmployeeDetails.vue")
                },
                {
                    path: "/my-payslips",
                    name: "my-payslips",
                    component: () =>
                        import ("./views/pages/employees/Payslips.vue")
                },
                {
                    path: "/my-payslip/:emp_id/details/:id",
                    name: "my-payslip-details",
                    component: () =>
                        import ("./views/pages/employees/Payslip.vue")
                },
                {
                    path: "/employee/my-history",
                    name: "my-employee-history",
                    component: () =>
                        import ("./views/pages/employees/History.vue")
                },
                {
                    path: "/my-attendance",
                    name: "my-attendance",
                    component: () =>
                        import ("./views/pages/employee-attendance/my-attendance.vue")
                },
                {
                    path: "/team-attendance",
                    name: "team-attendance",
                    component: () =>
                        import ("./views/pages/employee-attendance/team-attendance.vue")
                },
                {
                    path: "/apply-leave",
                    name: "apply-leave",
                    component: () =>
                        import ("./views/pages/leaves/Add.vue")
                },
                {
                    path: "/my-leaves",
                    name: "my-leaves",
                    component: () =>
                        import ("./views/pages/leaves/Index.vue")
                },
                {
                    path: "/leave-approvals",
                    name: "leave-approvals",
                    component: () =>
                        import ("./views/pages/leave-approvals/Index.vue")
                },
                {
                    path: "/leave-approvals/leave-approval-details/:id",
                    name: "leave-approval-details",
                    component: () =>
                        import ("./views/pages/leave-approvals/View.vue")
                },
                {
                    path: "/my-holidays",
                    name: "my-holidays",
                    component: () =>
                        import ("./views/pages/my-holidays/Index.vue")
                },
                {
                    path: "/my-team",
                    name: "my-team",
                    component: () =>
                        import ("./views/pages/my-team/Index.vue")
                },
                {
                    path: "/employee-shifts",
                    name: "employee-shifts",
                    component: () =>
                        import ("./views/pages/employee-shifts/Index.vue")
                },
                {
                    path: "/employee-shifts/assign",
                    name: "assign-employee-shift",
                    component: () =>
                        import ("./views/pages/employee-shifts/Add.vue")
                },
                {
                    path: "/edit-employee-shifts/:id",
                    name: "edit-employee-shift",
                    component: () =>
                        import ("./views/pages/employee-shifts/Edit.vue")
                },
                {
                    path: "/my-leave-balance",
                    name: "my-leave-balance",
                    component: () => 
                        import ("./views/pages/leave-balance/MyLeaveBalanceHistory.vue")
                },
                {
                    path: "/apply-employee-leaves/",
                    name: "apply-employee-leaves",
                    component: () =>
                        import ("./views/pages/leaves/ApplyEmployeeLeave.vue")
                },
                {
                    path: "/team-leave-balance",
                    name: "team-leave-balance",
                    component: () => 
                        import ("./views/pages/leave-balance/LeaveBalanceHistory.vue")
                },
                {
                    path: "/comp-offs",
                    name: "comp-offs",
                    component: () => 
                        import ("./views/pages/compoffs/Index.vue")
                },
                {
                    path: "/compoff-approvals",
                    name: "compoff-approvals",
                    component: () => 
                        import ("./views/pages/compoff-approval/Index.vue")
                },
                {
                    path: "/compoff-approvals/compoff-approval-details/:id",
                    name: "compoff-approval-details",
                    component: () =>
                        import ("./views/pages/compoff-approval/View.vue")
                },
                {
                    path: "/comp-offs/apply",
                    name: "apply-compoff",
                    component: () => 
                        import ("./views/pages/compoffs/Create.vue")
                },
                {
                    path: "/profile",
                    name: "profile",
                    component: () =>
                        import ("./views/user/Profile.vue")
                },
                {
                    path: "/settings",
                    name: "settings",
                    component: () =>
                        import ("./views/user/Settings.vue")
                },
                {
                    path: "/my-shifts",
                    name: "my-shifts",
                    component: () =>
                        import ("./views/pages/shifts/Index.vue")
                },
                {
                    path: "/apply-leave-encash",
                    name: "apply-leave-encash",
                    component: () =>
                        import ("./views/pages/leave-encash/Add.vue")
                },
                {
                    path: "/leave-encash",
                    name: "leave-encash",
                    component: () =>
                        import ("./views/pages/leave-encash/Index.vue")
                },


            ]
        },
        {
            path: "/login",
            name: "login",
            component: () =>
                import ("./views/auth/Login.vue")
        },
        {
            path: "/404",
            name: "404",
            component: () =>
                import ("./views/errors/NotFound.vue")
        },
        {
            path: "*",
            redirect: "/404"
        }
    ]
});
router.beforeEach((to, from, next) => {
    document.title = 'Infoesearch Portal';
    const publicPages = ["/login", "/404"];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired) {
        if (window.$cookies.isKey("user")) {
            const user = window.$cookies.get("user");
            if (user.access_token != (null || undefined)) {
                const permit = user.user_details.permissions.filter(
                    item => (item == 'CAN_ACCESS_PORTAL_UI')
                );
                if(permit.length == 0) {
                    next('/404')
                }
                next();
            } else {
                return next("/login");
            }
        } else {
            return next("/login");
        }
    }
    next();
});
export default router;
